/* CSS for Responsive Table Design with Scrolls */

.header-manage-domains {
    background-color: white;
    color: black;
    padding: 20px 10px;
   
    display: flex;
  }

  .column-one-manage-domains {
    width: 40%;
    color: black;
  }

  /* Hide the div on small screens */
@media (max-width: 767px) {
  .column-one-manage-domains {
    display: none;
  }
}
  
  .column-two-manage-domains {
    width: 20%;
    align-items: center;
  }

  /* Hide the div on small screens */
@media (max-width: 767px) {
  .column-two-manage-domains {
    display: none;
  }
}
  
  .column-three-manage-domains {
    display: flex;
  
    width: 40%;
    text-align: right;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
  }
  

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    overflow-x: auto; /* Add horizontal scroll */
}

th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

th {
    background-color: #f2f2f2;
}

/* Add vertical scroll for small screens */
@media (max-width: 768px) {
    table {
        overflow-y: auto; /* Add vertical scroll */
    }
    
    /* Add more styles as needed */
}


.txt-record-container {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
}

.txt-record {
  margin: 10px 0;
  font-family: monospace;
  word-break: break-all;
}

.txt-record-button {
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
}

.txt-record-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Apply styles to all input elements */
input[type="text"] {
  width: 100%; /* Make the input element take up 100% of its parent's width */
  padding: 10px; /* Add padding inside the input element */
  margin-bottom: 10px; /* Add some space between input elements */
  border: 1px solid #ccc; /* Add a border around the input element */
  border-radius: 5px; /* Add rounded corners to the input element */
  font-size: 12px; /* Set the font size */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

/* Apply styles when the input element is focused */
input[type="text"]:focus {
  outline: none; /* Remove the default focus outline */
  
  box-shadow: 0 0 1.5px rgba(90, 90, 90); /* Add a box shadow when focused */
}


/* Apply styles to all input elements */
input[type="url"] {
  width: 100%; /* Make the input element take up 100% of its parent's width */
  padding: 10px; /* Add padding inside the input element */
  margin-bottom: 10px; /* Add some space between input elements */
  border: 1px solid #ccc; /* Add a border around the input element */
  border-radius: 5px; /* Add rounded corners to the input element */
  font-size: 12px; /* Set the font size */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

/* Apply styles when the input element is focused */
input[type="url"]:focus {
  outline: none; /* Remove the default focus outline */
  box-shadow: 0 0 1.5px rgba(90, 90, 90); /* Add a box shadow when focused */
}



/* Apply styles to all textarea elements */
textarea {
  width: 100%; /* Make the textarea take up 100% of its parent's width */
  height: 80px; /* Set the initial height of the textarea */
  padding: 10px; /* Add padding inside the textarea */
  margin-bottom: 10px; /* Add some space between textarea elements */
  border: 1px solid #ccc; /* Add a border around the textarea */
  border-radius: 5px; /* Add rounded corners to the textarea */
  font-size: 16px; /* Set the font size */
  resize: vertical; /* Allow vertical resizing */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

/* Apply styles when the textarea element is focused */
textarea:focus {
  outline: none; /* Remove the default focus outline */
  box-shadow: 0 0 1.5px rgba(90, 90, 90); /* Add a box shadow when focused */
}

/* Apply styles to all select elements */
select {
  width: 100%; /* Make the select element take up 100% of its parent's width */
  padding: 10px; /* Add padding inside the select element */
  margin-bottom: 10px; /* Add some space between select elements */
  border: 1px solid #ccc; /* Add a border around the select element */
  border-radius: 5px; /* Add rounded corners to the select element */
  font-size: 12px; /* Set the font size */
  background-color: white; /* Set the background color */
  color: #333; /* Set the text color */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

/* Apply styles when the select element is focused */
select:focus {
  outline: none; /* Remove the default focus outline */
  box-shadow: 0 0 1.5px rgba(90, 90, 90); /* Add a box shadow when focused */
}

.edit-input-row {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 768px) {
  .edit-input-row {
    flex-direction: column;
  }
}

