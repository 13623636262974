/* Apply styles to the container of your component */

  .header-account-settings {
    background-color: white;
    color: black;
    padding: 20px 10px;
   
    display: flex;
}

.parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    background-color: white;
}

.settings-container {
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #ffffff;
  width: 60%;
  position: absolute; /* Set position to absolute */
  top: 20px; /* Adjust the top value to move the container down by 50 pixels */
}

/* Apply 100% width for small screens */
@media (max-width: 767px) {
  .settings-container {
    width: 90%;
    left: 0;
    right: 0;
  }
}

.profile-picture-header {
  width: 30px; /* Set the desired width for the circular profile picture */
  height: 30px; /* Set the desired height for the circular profile picture */
  border-radius: 50%; /* Make the image circular */
  object-fit: cover; /* Maintain aspect ratio and cover the entire element */
  margin-right: 10px;
}

.profile-container {
  background-color: white;
  color: black;
  display: flex;
  align-items: center; /* Align items vertically in the flex container */
  margin-left: 10px;
  margin-right: 10px;
}


.column-one-account-settings {
  width: 40%;
  color: black;
}


/* Hide the div on small screens */
@media (max-width: 767px) {
  .column-one-account-settings {
    display: none;
  }
}

.column-two-account-settings {
  width: 20%;
  align-items: center;
}

/* Hide the div on small screens */
@media (max-width: 767px) {
  .column-two-account-settings {
    display: none;
  }
}

.column-three-account-settings {
  display: flex;

  width: 40%;
  text-align: right;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
}


  /* CSS */
.verification-section {
    text-align: right;
}



  /* Improve form layout */
  .settings-container div {
    margin-bottom: 10px; /* Reduced form element spacing */
  }
  
  /* Style input fields */
  .settings-container input[type="text"],
  .settings-container input[type="password"] {
    padding: 8px; /* Reduced input field padding */
    border: 1px solid #ccc;
    border-radius: 3px;
    width: calc(100% - 18px); /* Adjusted width */
    font-size: 14px; /* Reduced font size */
  }
  
  /* Add focus styles to input fields */
  .settings-container input[type="text"]:focus,
  .settings-container input[type="password"]:focus {
    outline: none;
    border-color: #4f4f4f;
  }
  
  /* Style buttons */
  .settings-container button {
    background-color: #4f4f4f;
    color: white;
    border: none;
    border-radius: 3px;
    padding: 8px 16px; /* Reduced padding */
    cursor: pointer;
    font-size: 14px; /* Reduced font size */
  }
  
  .settings-container button:hover {
    background-color: #4f4f4f;
  }
  
  /* Style the Save Account Settings button inside the Link component */
  .settings-container a button {
    background-color: #4f4f4f;
    color: white;
    text-decoration: none;
    border: none;
    border-radius: 3px;
    padding: 8px 16px; /* Reduced padding */
    cursor: pointer;
    font-size: 14px; /* Reduced font size */
  }
  
  .settings-container a button:hover {
    background-color: #4f4f4f;
  }
  
  /* Add hover effect to form elements */
  .settings-container input[type="text"]:hover,
  .settings-container input[type="password"]:hover {
    border-color: #4f4f4f;
  }
  
  /* Add hover effect to buttons */
  .settings-container button:hover,
  .settings-container a button:hover {
    background-color: #4f4f4f;
  }
  
  /* Improve typography */
  .settings-container h3 {
    font-size: 1.2em; /* Slightly reduced heading font size */
    margin-bottom: 10px;
  }
  
  .settings-container p {
    margin-bottom: 8px; /* Reduced paragraph margin */
    font-size: 14px; /* Reduced paragraph font size */
  }

  .password-change-section input {
    margin-bottom: 10px; /* Adjust this value to control the vertical spacing */
  }
  
  .card-title {
    font-size: 24px; /* Adjust the font size as needed */
    margin-bottom: 16px; /* Add margin bottom to create space between title and content */
    justify-content: center;
	align-items: center;
  }

  .profile-picture-settings {
    width: 30px; /* Set the desired width for the circular profile picture */
    height: 30px; /* Set the desired height for the circular profile picture */
    border-radius: 50%; /* Make the image circular */
    object-fit: cover; /* Maintain aspect ratio and cover the entire element */
    margin-left: 10px; /* Center the picture horizontally and vertically */
  }

  .column-one-edit-profile-picture-settings {
    /* Add your styles here */
    float: right;
    /* Other styles */
}
