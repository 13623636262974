.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}


 /* Media query for small screens */
 @media screen and (max-width: 768px) {
  .loading-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
}
.loading-spinner {
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top: 5px solid #5A5A5A;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
