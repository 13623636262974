


.user-profile-popup {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    /* Additional styles for centering the content */
    display: flex;
    align-items: center;
    justify-content: left;
    
  
  }