/* SignIn.css */

.main {
	margin-top: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 25%;
	background-color: #ffffff;
  }
  
  .sign-in-parent-container {
	display: flex;
	max-width: 1200px;
	width: 100%;
	align-items: center;
  }
  
  .header-account-settings {
	display: flex;
	flex-direction: column;
	align-items: left;
  }
  
  .additional-content {
	margin-top: 16px; /* Adjust this value as needed */
	align-items: left;
	font-size: 18px;
  }
  
  .card-container {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
  }
  
  .card {
	max-width: 900px;
	
	padding: 32px;
	border-radius: 12px;
	border: 1px solid #ccc;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	background-color: #ffffff;
  }

  
  
  /* Additional styles for the big heading on the left */
  .header-account-settings h1 {
	font-size: 24px;
	font-weight: 800;
	margin-bottom: 16px;
  }
  
  /* Additional styles for the card on the right */
  .card h2 {
	font-size: 20px;
	font-weight: 900;
	margin-bottom: 16px;

  }
  

  

  input[type="email"],
input[type="password"] {
  width: 100%; /* Make the input element take up 100% of its parent's width */
  padding: 10px; /* Add padding inside the input element */
  margin-bottom: 10px; /* Add some space between input elements */
  border: 1px solid #ccc; /* Add a border around the input element */
  border-radius: 5px; /* Add rounded corners to the input element */
  font-size: 12px; /* Set the font size */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

/* Apply styles when the input element is focused */
input[type="email"]:focus,
input[type="password"]:focus {
  outline: none; /* Remove the default focus outline */
  box-shadow: 0 0 1.5px rgba(90, 90, 90); /* Add a box shadow when focused */
}

  .input-submit {
	margin: 5px;
	padding: 6px 12px;
	font-size: 15px;
	border: 1px solid grey;
	border-radius: 5px;
	background-color: #2E2EFF;
	color: #ffffff;
	cursor: pointer;
  }
  
  
  /* Add other styles as needed */
  