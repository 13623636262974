/* Add this to your CSS or create a separate CSS file */
.notification {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    opacity: 0.9;
    z-index: 1000;
  }
  