.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  z-index: 1;
  bottom: 110%; /* Position the tooltip above the element */
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position the tooltip above the text */
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

.typing-animation {
  /* Animation styles */
  
  white-space: nowrap;
  overflow: hidden;
  border-right: 2px solid white;
  caret-color: red;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}