 /* Add this to your CSS file */
 .container {
    display: flex;
   
    width: 65%;

    
  }

  @media (max-width: 767px) {
    .container {
      width: 100%;
      top: 0%;
    }
  }
  



  .section1-section1-profile-cover {
   background-color: blue;
  }
  
  .section2-section2-user-info {
   
  }
  
  .section3-domain-portfolio {
   
  }

    /* Media query for small screens */
@media screen and (max-width: 768px) {
  
  .section1-section1-profile-cover {
    width: 100%; /* Set columns to 100% width for small screens */
      display: flex;
      flex-wrap: wrap;
      text-align: right;
      align-items: right;
      margin-bottom: 10px; /* Optional: Add margin between columns for small screens */

  }

  .section2-section2-user-info {
    width: 100%; /* Set columns to 100% width for small screens */
    margin-top: 10px; /* Optional: Add margin between columns for small screens */
      flex-wrap: wrap;
      text-align: center;
      align-items: center;
      margin-bottom: 10px; /* Optional: Add margin between columns for small screens */
  }

  .section3-domain-portfolio {
    width: 100%; /* Set columns to 100% width for small screens */
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      align-items: center;
      margin-bottom: 10px; /* Optional: Add margin between columns for small screens */
  }
}
  

  /* Hide the div on small screens */
@media (max-width: 767px) {
  .profile-top-cover {
    display: none;
  }
}

  .column-one-profile {
    width: 20vw;
  }
  
  .column-two-profile {
    width: 20vw;
    text-align: center;
  }
  
  .column-three-profile {
    width: 20vw;
    text-align: center;
  }

  /* Media query for small screens */
@media screen and (max-width: 768px) {
  
  .column-one-profile {
    width: 50%; /* Set columns to 100% width for small screens */
      display: flex;
      
      text-align: center;
      align-items: center;
      margin-bottom: 10px; /* Optional: Add margin between columns for small screens */
  }

  .column-two-profile {
    width: 50%; /* Set columns to 100% width for small screens */
      
     
      text-align: center;
      align-items: center;
       /* Optional: Add margin between columns for small screens */
  }

  .column-three-profile {
    width: 100%; /* Set columns to 100% width for small screens */
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
      align-items: center;
      border: 1px solid black;
      border-radius: 5px;
      justify-content: space-around;
      padding-top: 5px;
     
  }

  .column-three-profile .flex-item {
    width: 100%; /* Ensure flex items take up full width */
    /* Additional styles for flex items if needed */
  }
}

  .profile-chat-contact {
    margin-bottom: 10px;
    margin-left: 10px;
    text-align: center;
      align-items: center;
    
  }

  
  .profile-tel-contact {
    margin-bottom: 10px;
    margin-left: 10px;
    text-align: center;
      align-items: center;
  }

  .profile-mail-contact {
    margin-bottom: 10px;
    margin-left: 10px;
    text-align: center;
      align-items: center;
  }

  .contact-text {
    margin-left: 10px; /* Adjust the margin to create space between icon and link text */
  }
  
  .not-available-text {
    margin-left: 10px; /* Adjust the margin to create space between icon and "Not available" text */
  }



  .profile-picture {
    width: 120px; /* Set the desired width for the circular profile picture */
    height: 120px; /* Set the desired height for the circular profile picture */
    border-radius: 50%; /* Make the image circular */
    object-fit: cover; /* Maintain aspect ratio and cover the entire element */
    margin-left: 10px; /* Center the picture horizontally and vertically */
  }

  /* Styles for the main menu container in section 2 */
.main-menu-profile {
  display: flex;
  justify-content: space-around;
}

  /* Media query for small screens */
  @media screen and (max-width: 768px) {
    .main-menu-profile {
      width: 100%; /* Set columns to 100% width for small screens */
     
      flex-wrap: wrap;
      /* Optional: Add margin between columns for small screens */

      text-align: center;
      align-items: center;
    }
  }
  
  

  .greyIcon {
    color: grey;
  }


  @media (max-width: 768px) {
    .small-screen .expand-button {
      display: block; /* Show the expand button for small screens */
    }
  
    .small-screen .expanded-row {
      display: table-row; /* Show the expanded row for small screens */
    }
  
    .small-screen .expanded-content {
      display: table-cell; /* Show the expanded content for small screens */
    }
  }

  @media screen and (max-width: 767px) {
    .big-screen-button {
      display: none; /* Hide the button for big screens on small screens */
    }
  }
  
  /* Media query for big screens */
  @media screen and (min-width: 768px) {
    .small-screen-button {
      display: none; /* Hide the button for small screens on big screens */
      align-items: right;
      text-align: right;
    }
  }
  