/* SignIn.css */

.main {
	margin-top: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 25%;
	background-color: #ffffff;
  }
  
  .sign-in-parent-container {
	
	display: flex;
	max-width: 1200px;
	width: 100%;
	justify-content: center;
  }
  
  .header-account-settings {
	
	display: flex;
	flex-direction: column;
	align-items: left;
  }
  
  .additional-content {
	margin-top: 16px; /* Adjust this value as needed */
	align-items: left;
	font-size: 18px;
  }

  
  /* CSS to remove bullet points */
  ul {
    list-style: none; /* Remove default bullet points */
    padding-left: 0; /* Optional: Remove default padding */
  }

  
  .card-container {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
  }
  
  .card {
	max-width: 900px;
	padding: 32px;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	background-color: #ffffff;
  }
  
  /* Additional styles for the big heading on the left */
  .header-account-settings h1 {
	font-size: 24px;
	font-weight: 800;
	margin-bottom: 16px;
  }

  
  /* Additional styles for the card on the right */
  .card h2 {
	font-size: 20px;
	font-weight: 900;
	margin-bottom: 16px;

  }

  .sign-in-input-control {
    margin-bottom: 10px; /* Adjust this value to control the vertical spacing */
  }
  
  /* Add other styles as needed */
  
  .icon-with-text {
	display: flex;
	align-items: center;
  }
  
  .icon-text {
	margin-left: 8px; /* Adjust the spacing as needed */
	color: black; /* Match the color of the icon */
	font-size: 18px; /* Adjust the font size as needed */
  }

  
  .styled-link {
	text-decoration: none;
	color: blue; /* Example color: a shade of orange */
  }
  
  .styled-link:hover {
	color: blue; /* Example hover color: a shade of red */
  }